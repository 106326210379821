// Constants.js
const cookieDomain = (function() {
    if (window.location.hostname === "localhost") return "localhost"
    else return window.location.hostname.substring(5) // we remove "admin" from the host, support custom domains 
})()

export const config = {
    url: {
        API: window._env_.API_URL,
        GUEST: window._env_.GUEST_URL,
        PUBLIC: window._env_.PUBLIC_URL,
        LANDING: window._env_.LANDING_URL,
        BOOKING_API: window._env_.BOOKING_API_URL,
        ADMIN: window._env_.ADMIN_URL,
        SITES: window._env_.SITES_URL || "https://$1.tas.ink",
        PREVIEW: window._env_.PREVIEW_URL || "https://preview.takeaseat.io",
        PUBLICPAGE: window._env_.WEBSITE_URL,
        MARKETINGVIEW: 'https://mktg.takeaseat.io'
    },
    cookies: {
        domain: cookieDomain
    }
}
