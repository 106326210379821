import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './routes';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { bestLanguage } from "./utils/LanguageUtils";
import Cookies from 'js-cookie'
import { config } from './Constants'


const lang = bestLanguage(true, Cookies.get("lang"), ["en", "fr", "es", "de"]) // , "de"

function loadResource(lang) {
  return {
    login: require(`./translations/${lang}/login.json`),
    app: require(`./translations/${lang}/app.json`),
    pricing: require(`./translations/${lang}/pricing.json`),
    // contactusbubble: require(`./translations/${lang}/contactusbubble.json`),
    register: require(`./translations/${lang}/register.json`),
    resetpassword: require(`./translations/${lang}/resetpassword.json`),
    publicpage: require(`./translations/${lang}/publicpage.json`),
    myorders: require(`./translations/${lang}/myorders.json`)
  }
}

window.sendEvent = function (event, subEvent) {
  fetch(`${config.url.API}/event`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({ event, subEvent })
  })
}

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: lang,
  resources: { 'en': loadResource('en'), 'fr': loadResource('fr'), 'es': loadResource('es'), 'de': loadResource('de') }
});
//, 'de': loadResource('de')

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Main />
  </I18nextProvider>, document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
