import React, { Component } from 'react';
import { Route } from 'react-router';
import LoginPage from './LoginPage';
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { config } from './Constants';
import { withTranslation } from "react-i18next";
import Cookies from 'js-cookie'

const ResetPasswordPage = React.lazy(() => import('./ResetPassword'));
const SetupFlow = React.lazy(() => import('./SetupFlow'));
const EmailVerificationPage = React.lazy(() => import('./EmailVerificationPage'));
const RegisterPage = React.lazy(() => import('./RegisterPage'));
const App = React.lazy(() => import('./App'));


const Fallback = () => {
	return <div className={"splash-screen"}>
		<svg width="22.447mm" height="17.909mm" version="1.1" viewBox="0 0 22.447 17.909" xmlns="http://www.w3.org/2000/svg">
			<g transform="translate(-110.57 -60.892)" fill="none">
				<g transform="matrix(1.269 0 0 1.269 -11.238 -118.66)" strokeWidth=".5">
					<path stroke="#2CB052" d="m96.599 143.28c2.4528-0.51162 4.5756-1.3847 7.3584-1.5349l1.8725 0.0183c2.019 0.12141 4.8933 1.0189 7.2593 1.5207l-8.2178 1.933z" />
					<path stroke="#0098FF" d="m96.251 144.01 8.2532 2.0071 0.0168 9.2449-2.1118-0.69584c-0.14624-0.0904-0.31129-0.1-0.3648-0.58862l-0.0239-5.9557-4.2732-1.075c-0.96928-0.53789-1.5987-1.3944-1.4963-2.9368z" />
					<path stroke="#EF7902" d="m113.42 143.98-8.2532 2.0071-0.0168 9.2449 2.1118-0.69584c0.14625-0.0904 0.3113-0.1 0.3648-0.58862l0.0239-5.9557 4.2732-1.075c0.96928-0.53789 1.5987-1.3944 1.4963-2.9368z" />
				</g>
			</g>
		</svg>
	</div>
}


class Main extends Component {
	constructor(props) {
		super(props);

		const { t, i18n } = props
		this.i18n = i18n;

		this.state = {
			// The restaurantId of the admin user in the session. It
			// is initialized to undefined before we have checked the
			// backend for the state, then it's either a valid ID or
			// the empty string ('') if the user is not logged in.
			restaurantId: undefined, // restaurantId is undefined until we have at least tried to get it.
			redirectPath: undefined,
			do_setup: false
		}
		this.isLoggedIn = this.isLoggedIn.bind(this);
		this.clearRestaurantId = this.clearRestaurantId.bind(this);
		this.onLogin = this.onLogin.bind(this);
		this.doSetup = this.doSetup.bind(this);
		this.onSetupFinish = this.onSetupFinish.bind(this);
		this.refreshMetadata = this.refreshMetadata.bind(this);
		// this.noSetup = this.noSetup.bind(this);
	}

	componentDidMount() {
		// Initial check if the user is already logged in (due to its cookie), if
		// so, we restore the correct props.restaurantId. Note that this is only run
		// on a page refresh, not on every redirect.
		this.isLoggedIn((restaurantId) => {
			if (restaurantId) {
				this.loadMetadata(restaurantId).then((metadata) => {
					this.setState({ do_setup: (metadata && metadata.setup !== "completed"), restaurantId, metadata })
				})
			} else {
				this.setState({ restaurantId: "" }) // not undefined, we put empty string
			}
		})
	}

	// TODO: put into utils 
	async loadMetadata(restaurantId) {
		return fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/metadata.json`, {
			method: 'get'
		}).then(resp => {
			if (resp.status === 200) {
				return resp.json()
			}
		})
	}

	refreshMetadata(metadata) {
		this.state.metadata = metadata; // TODO: use setState?
	}

	clearRestaurantId() {
		this.setState({ restaurantId: "" });
	}

	onLogin(restaurantId, redirectTo) {
		// If redirectTo is not undefined, we set it as the next page after login (works only on first login)
		this.loadMetadata(restaurantId).then((metadata) => {
			this.setState({ do_setup: (metadata && metadata.setup !== "completed"), restaurantId, metadata, redirectTo })  // if on register page, metadata is not defined.
		})
	}

	onSetupFinish(restaurantId, redirectTo) {
		this.loadMetadata(restaurantId).then((metadata) => {
			this.metadata = metadata
			this.setState({ do_setup: (metadata && metadata.setup !== "completed"), restaurantId, redirectTo })
			// window.gtag('event', 'setup_completed');
		})
	}

	// TODO: still useful?
	doSetup(id, lang, prebuilt) {
		this.i18n.changeLanguage(lang)

		const current = new Date();
		const nextYear = new Date();
		nextYear.setFullYear(current.getFullYear() + 1);

		Cookies.set("lang", lang, { domain: config.cookies.domain, expires: nextYear })
		this.setState({ restaurantId: id, do_setup: true, prebuilt });
	}

	// TODO: Put in Login utils file
	isLoggedIn(callback) {
		fetch(`${config.url.API}/restaurant/id`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(resp => {
			if (resp.status === 403) {
				callback('');
			} else {
				resp.json().then(id => callback(id));
			}
		}).catch(e => callback(''))
	}

	render() {
		if (this.state.restaurantId === undefined) return <></> // We need to attempt to load user id first

		return <Router>
			{((!this.state.do_setup) && this.state.redirectTo) && <Redirect to={this.state.redirectTo} />}

			<Route path="/" render={({ location }) => {
				// TODO: also take into account the internal views in the admin UI.
				if (typeof window.gtag === 'function') {
					window.gtag('event', 'page_view', {
						page_path: location.pathname + location.search
					});
				}
				return null;
			}} />

			<Switch>
				{ // The restaurant is known and has not completed the setup yet. Finish setup.
					this.state.restaurantId && this.state.do_setup &&
					<Route path="/">
						<React.Suspense fallback={<Fallback />}>
							<SetupFlow
								restaurantId={this.state.restaurantId}
								refreshMetadata={this.refreshMetadata}
								prebuilt={this.state.prebuilt}
								onFinish={this.onSetupFinish}
								onLogout={this.clearRestaurantId}
								metadata={this.state.metadata} />
						</React.Suspense>
					</Route>
				}
				<Route exact path="/register" render={(props) => {
					return <React.Suspense fallback={<Fallback />}>
						<RegisterPage {...props} />
					</React.Suspense>
				}} />
				<Route exact path="/reset_password">
					<React.Suspense fallback={<Fallback />}>
						<ResetPasswordPage />
					</React.Suspense>
				</Route>
				{
					!this.state.restaurantId &&
					<Route exact path="/login" render={(props) => {
						return <LoginPage
							onLogin={this.onLogin}
							restaurantId={this.state.restaurantId}
							redirectPath={this.state.redirectPath}
							{...props} />
					}} />
				}
				<Route exact
					path="/verify-email/:verificationCode"
					render={(props) => {
						const verificationCode = props.match.params.verificationCode;
						return <React.Suspense fallback={<Fallback />}>
							<EmailVerificationPage
								verificationCode={verificationCode}
								onValidate={this.doSetup} />
						</React.Suspense>
					}} />
				{this.state.restaurantId &&
					<Route path="/" render={(props) => {
						return <React.Suspense fallback={<Fallback />}>
							<App
								restaurantId={this.state.restaurantId}
								onLogout={this.clearRestaurantId}
								onLogin={this.onLogin}
								pathname={this.state.redirectTo || this.state.redirectPath}
								{...props}
								search={props.location.search}
								metadata={this.state.metadata} />
						</React.Suspense>
					}}>
					</Route>
				}
				{/* User tried to access a page but is not logged in. Save the page the user tried to access to 
				redirectPath state and redirects user to the login page.*/}
				<Route path="/" render={({ location }) => {
					if (this.state.do_setup) return <Redirect to='/setup' /> // TODO: is it used?

					this.setState({ redirectPath: location.pathname });
					return <Redirect to="/login" />
				}} />
			</Switch>
		</Router>
	}
}

// TODO: define with metadata as for guest.

const MainTranslated = withTranslation('app')(Main);

export default MainTranslated;
