import React, { Component } from 'react';
import { config } from './Constants';
import { withTranslation } from "react-i18next";
import LanguagePicker from "./LanguagePicker";
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';


class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = { email: "", password: "", loginFailed: false, isSubmitting: false, isSubmittingGoogle: false };
	}

	render() {
		const { t } = this.props;

		return <div id="login-page">
			<div className="lg-screen" style={{
				position: "absolute", width: "100%", zIndex: "1", top: "1rem", paddingLeft: "1rem", display: "flex",
				justifyContent: "space-between"
			}}>
				<a className="appbar-logo" href={config.url.LANDING} >
					<img alt="Take a Seat logo" src={`https://takeaseat.io/media/icons/tas-230526.svg`} />
					<div>Take a Seat</div>
				</a>
				<LanguagePicker />
			</div>
			<div>
				<picture>
					<source srcSet={"/static/media/register-bg.webp"} type="image/webp" width="0" height="0" loading="lazy" />
					<source srcSet={"/static/media/register-bg.jpg"} type="image/jpeg" width="0" height="0" loading="lazy" />
					<img alt="Setup process" style={{ width: "100vw", height: "100vh", filter: "blur(7px)", objectFit: "cover" }} width="0" height="0" loading="lazy" />
				</picture>
			</div>
			<div className={"login-page-content"}>
				<div className="logo"></div>
				<h1>{t('title')}</h1>
				<form onSubmit={(e) => {
					e.preventDefault()

					var xhr = new XMLHttpRequest();
					xhr.open("POST", `${config.url.API}/login`)
					xhr.setRequestHeader('Content-Type', 'application/json');
					xhr.withCredentials = true;
					xhr.onload = function (e) {
						if (xhr.readyState === 4) {
							if (xhr.status === 200) {
								const ga_user = JSON.parse(xhr.responseText)["ga_user"];
								window._env_.GA_CONFIGS.forEach((c) => {
									// window.gtag("config", c, { 'user_id': ga_user });
								});
								// Method tag can be things like Facebook account, Google account, email.
								// window.gtag('event', 'login', { 'method': 'Email' })
								const restaurantId = JSON.parse(xhr.responseText)["id"];
								// props.onLogin should handle the redirection/change of component.
								this.props.onLogin(restaurantId, this.props.redirectPath ? this.props.redirectPath : "/");
							} else if (xhr.status === 401) {
								this.setState({ loginFailed: true, isSubmitting: false })
							}
							else {
								console.error(xhr.statusText);
							}
						}
					}.bind(this);
					xhr.onerror = function (e) {
						console.error(xhr.statusText);
					};
					xhr.send(JSON.stringify({ "email": this.state.email, "password": this.state.password }));
					this.setState({ isSubmitting: true })
				}}>
					<div className="login-field" >
						<PersonIcon />
						<input
							type="email"
							name="email"
							placeholder={t('email')}
							onChange={(e) => this.setState({ email: e.target.value })} />
					</div>
					<div className="login-field">
						<KeyIcon />
						<input
							type="password"
							placeholder={t('password')}
							name="password"
							onChange={(e) => this.setState({ password: e.target.value })} />
					</div>
					<button type="submit" className="tas-button">{this.state.isSubmitting ? <div className="loader">Loading...</div> : "Login"}</button>
					<div style={{ textAlign: "center", width: "100%", marginTop: "1rem" }}><a href="/reset_password">{t("password-forgotten")}</a></div>
					{this.state.loginFailed && <div className="login error-message">{t("login-failed")}</div>}
				</form>
				<div className="login-sep"><span>{t("or")}</span></div>
				<div className="login-google" onClick={() => {
					this.setState({ isSubmittingGoogle: true })
					window.location.href = `${config.url.API}/auth/google`
				}} >
					{this.state.isSubmittingGoogle ? <div className="loader">Loading...</div> :
						<>
							<img src='/static/media/icons/google-icon.png' alt="Google icon" />
							<span>{t("google-log-in")}</span>
						</>}
				</div>
				<div className="login-sep"></div>
				<div> {t("no-account-yet")} <a href="/register">{t("create-account")}</a> </div>
			</div>
		</div>
	}
}

const LoginPageTranslated = withTranslation('login')(LoginPage)

export default LoginPageTranslated;
