import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import './LanguagePicker.css';
import Cookies from 'js-cookie'
import { config } from './Constants'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


class LanguagePicker extends Component {
    constructor(props) {
        super(props);

        const { i18n } = props
        this.i18n = i18n

        this.state = { email: "", password: "", redirect: false, loginFailed: false };
    }

    setLang({ lang }) {
        this.i18n.changeLanguage(lang);

        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        Cookies.set("lang", lang, { domain: config.cookies.domain, expires: nextYear })
    }

    render() {
        const { i18n } = this.props;

        return <div id="lang-picker">
            <Select
                id="lang-picker-select"
                value={i18n.language}
                size="small"
                onChange={(e) => this.setLang({ lang: e.target.value })}>
                {/* <MenuItem value={"de"}>Deutsch</MenuItem> */}
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"es"}>Español</MenuItem>
                <MenuItem value={"fr"}>Français</MenuItem>
                <MenuItem value={"pt"}>Português</MenuItem>
            </Select>
        </div>
    }
}

const LanguagePickerTranslated = withTranslation('language-picker')(LanguagePicker)

export default LanguagePickerTranslated;