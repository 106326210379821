import { config } from '../Constants'


export function bestLanguage(autoLanguage = true, selectedLanguage = null, supportedLanguages = ["en"]) {
    if (supportedLanguages.includes(selectedLanguage)) return selectedLanguage;
    if (autoLanguage & supportedLanguages.includes(browserLanguage())) return browserLanguage();
    return "en";
}

export function browserLanguage() {
    if ("language" in navigator && navigator.language !== "") {
        // The navigator.language returns locales including the country (en-US for example).
        // It's probably overkill to provide localization per country, so we are only interested
        // in the language part.
        return navigator.language.substr(0, 2);
    } else {
        return "en";
    }
}

export function changeLanguagePreference(lang, restaurantId, callback403) {
    fetch(`${config.url.API}/restaurants/${restaurantId}`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
            lang: lang
        })
    }).then(resp => {
        if (resp.status == 403) {
            callback403()
        } else if (resp.status >= 400) {
            alert("Error returned by server: " + resp.statusText);
        }
    });
}